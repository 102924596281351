<template>
  <main class="container">
    <ContentHeader :title="content_header.title" />

    <div class="box">
      <div class="no-border-box">
        <button
          class="btn download-box"
          style="background: #e9e7e7; color: #000000"
          @click="goPrevious"
        >
          <img
            src="@/assets/img/arrow-left-circle-fill.svg"
            alt="delete"
            srcset=""
          />
          Previous Page
        </button>
      </div>

      <!-- <download-excel
        class="btn download-box"
        :data="download"
        :fields="json_fields"
        name="process_customers.xls"
      >
        Get Upload Process
      </download-excel> -->
      <a
        class="btn download-box"
        @click="processDownload"
      >
        Get Upload Process
      </a>

    </div>

    <hr style="border: 1px solid #e5e5e5" />

    <div class="page__body">
      <div>
        <div class="page__body__header">
          <ul class="nav page__tabs page__tabs1" id="page-Tabs" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active text--capital active"
                id="pills-pending-tab"
                data-toggle="tab"
                href="#pills-pending"
                role="tab"
                aria-controls="pills-pending"
                aria-selected="true"
                >pending transactions</a
              >
            </li>

            <li class="nav-item">
              <a
                class="nav-link text--capital"
                id="pills-success-tab"
                data-toggle="tab"
                href="#pills-success"
                role="tab"
                aria-controls="pills-success"
                aria-selected="false"
                >succesful transactions</a
              >
            </li>

            <li class="nav-item">
              <a
                class="nav-link text--capital"
                id="pills-failedTransaction-tab"
                data-toggle="tab"
                href="#pills-failedTransaction"
                role="tab"
                aria-controls="pills-failedTransaction"
                aria-selected="false"
                >failed transactions</a
              >
            </li>
          </ul>
        </div>

        <div class="tab-content" id="page-TabsContent">
          <!-- View pending transaction -->
          <viewPendingTransactionTable
            :pendingTransactions="pendingTransactions"
            :loading="loading"
          />

          <!-- View successful transaction -->
          <viewSuccessTable
            :successTransactions="successTransactions"
            :loading="loading"
          />

          <!-- View failed transaction -->
          <viewFailedTransactionTable
            :failedTransactions="failedTransactions"
            :loading="loading"
          />
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapState } from "vuex";
// import JsonExcel from "vue-json-excel";
import moment from "moment";
import * as XLSX from "xlsx";

import { SINGLE_BULK_REPAYMENT_DETAILS } from "./../../../core/services/store/bulkrepayment.module";

export default {
  name: "ViewBulkManager",
  data() {
    return {
      content_header: {
        title: "Bulk Upload Process Manager",
      },
      successTransactions: [],
      failedTransactions: [],
      pendingTransactions: [],
      loading: true,
      json_fields: {
        "Customer Id": "customer_id",
        "First Name": "first_name",
        "Last Name": "last_name",
        "Amount Received": "amount_rec",
        "Payment Date": "payment_date",
        "Cheque Number": "cheque_num",
        "Transaction Status": "status",
      },
    };
  },
  components: {
    // downloadExcel: JsonExcel,
    viewSuccessTable: () =>
      import(
        /* WebpackChunkName: "viewSuccessTable" */ "@/view/pages/bulkManager/successfulTransaction.vue"
      ),

    viewPendingTransactionTable: () =>
      import(
        /* WebpackChunkName: "viewFailedTransactionTable" */ "@/view/pages/bulkManager/pendingTransactions.vue"
      ),

    viewFailedTransactionTable: () =>
      import(
        /* WebpackChunkName: "viewFailedTransactionTable" */ "@/view/pages/bulkManager/failedTransactions.vue"
      ),
  },

  watch: {
    bulkRepaymentsDetails: function (newValue) {
      if (newValue) {
        this.sendTransactions(newValue);
      }
    },
  },

  computed: {
    ...mapState({
      bulkRepaymentsDetails: (state) => {
        return state.bulkrepayment.singleBulkRepaymentDetails;
      },
    }),

    download() {
      let data = [];

      this.bulkRepaymentsDetails.forEach((item) => {
        let new_obj = {};
        new_obj.customer_id = item.customerKey;
        new_obj.first_name = item.firstName || "";
        new_obj.last_name = item.lastName || "";
        new_obj.amount_rec = this.addCommas(item.amountReceived);
        new_obj.payment_date = this.formatDate(item.paymentDate);
        new_obj.cheque_num = item.chequeNumber;
        new_obj.status = item.isTreated ? "Successful" : "Failed";

        data.push(new_obj);
      });

      return data;
    },
  },

  methods: {
    getBulkDetails() {
      this.loading = true;
      let batchKey = this.$route.params.key;
      this.$store.dispatch(SINGLE_BULK_REPAYMENT_DETAILS, { batchKey });

      this.sendTransactions(this.bulkRepaymentsDetails);
    },

    goPrevious() {
      this.$router.go(-1);
    },

    sendTransactions(data) {
      this.successTransactions = data.filter(
        (transaction) => transaction.isTreated === 1
      );
      this.pendingTransactions = data.filter(
        (transaction) => transaction.isTreated === 0
      );
      this.failedTransactions = data.filter(
        (transaction) => transaction.isTreated === 2
      );
      this.loading = false;
    },

    processDownload() {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Bulk Upload Process Download",
        Subject: "Bulk Upload Process Excel Sheet",
        Author: "Prolean",
        createdDate: Date.now(),
      };

      wb.SheetNames.push("BulkUploadProcess");

      let sheetData = [[
        "Customer Id",
        "Customer Name",
        // "Last Name",
        "Amount Received",
        "Payment Date",
        "Cheque Number",
        "Transaction Status",
      ]];
      this.bulkRepaymentsDetails.forEach((item) => {
        let new_arr = [];
        new_arr.push(item.customerKey);
        new_arr.push(item.customerName || "");
        // new_arr.push(item.lastName || "");
        new_arr.push(this.addCommas(item.amountReceived));
        new_arr.push(this.formatDate(item.paymentDate));
        new_arr.push(item.chequeNumber);
        new_arr.push(item.isTreated ? "Successful" : "Failed");

        sheetData.push(new_arr);
      });

      let ws = XLSX.utils.aoa_to_sheet(sheetData);

      wb.Sheets["BulkUploadProcess"] = ws;

      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },

    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);

      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }

      return buf;
    },

    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "bulk_upload_transactions.xlsx");
      document.body.appendChild(link);
      link.click();
    },

    addCommas(amount) {
      if (amount) {
        let fixedAmount = amount.toFixed(2);
        return fixedAmount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      } else {
        return "0.00";
      }
    },

    formatDate(data) {
      return moment(data).format("DD-MMM-YYYY");
    },
  },

  mounted() {
    this.getBulkDetails();
  },
};
</script>
<style lang="scss" scoped>
.page__tabs1 {
  grid-template-columns: repeat(5, 1fr) !important;
}
.no-border-box {
  width: 82%;
  // border: 1px solid #C4C4C4;
  // box-sizing: border-box;
  // border-radius: 7px;
  height: 45px;
}
.box {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.download-box {
  width: 185px;
  height: 45px;
  background: #000000;
  border-radius: 5px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;
  color: #ffffff;
}
</style>
